import { BaseModel } from "./base.model";

export class HeaderItem extends BaseModel {
  constructor(
    public active: true,
    public title: {[lang:string]: string},
    public link: {[lang:string]: string},
    public roles?: []
  ) {
    super();
  }
}
                         