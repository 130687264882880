<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Header items</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button class="mb-5 add-button" @click="addItem()"> Add </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          class="mb-5 save-button"
          @click="save()"
          :disabled="saveDisabled"
        >
          Save
        </b-button>
      </b-col>
    </b-row>

    <draggable
      class="list-group mb-5"
      tag="ul"
      :list="items"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <b-card
          class="list-group-item"
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          no-body
        >
          <ContentHeader v-if="item" :object="items" :index="itemIndex" />
          <b-collapse
            :id="`accordion-${itemIndex}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-tabs content-class="mt-3" nav-class="no-padding">
                <b-tab v-for="(language, index) in languages" :key="index">
                  <template #title>
                    <country-flag :country="language" size="normal" />
                  </template>
                  <!-- TITLE  -->
                  <b-form-group label="Title">
                    <b-form-input
                      name="title"
                      v-validate="'required|min:3|max:50'"
                      v-model="item.title[language]"
                    ></b-form-input>
                    <div
                      v-if="submitted && errors.has('title')"
                      class="alert-danger"
                    >
                      {{ errors.first("title") }}
                    </div>
                  </b-form-group>
                  <!-- LINK  -->
                  <b-form-group label="Link">
                    <b-form-input
                      name="link"
                      v-validate="'required|min:3|max:50'"
                      v-model="item.link[language]"
                    />
                    <div
                      v-if="submitted && errors.has('link')"
                      class="alert-danger"
                    >
                      {{ errors.first("link") }}
                    </div>
                  </b-form-group>
                </b-tab>
              </b-tabs>
              <!-- ROLES -->
              <b-form-group label="Roles">
                <b-form-checkbox-group plain v-model="item.roles">
                  <b-form-checkbox
                    v-for="(role, index) in roles"
                    :key="index"
                    :value="role"
                    :label="role"
                  >
                    {{ role }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </transition-group>
    </draggable>

    <HeaderDetail
      v-if="selectedItem !== null"
      v-model="showModal"
      :index="itemIndex"
      :item="selectedItem"
      :items="items"
    />
  </b-overlay>
</template>
<script>
import draggable from "vuedraggable";
import { HeaderItem } from "@/models/header-item.model.ts";
import HeaderDetail from "@/components/admin/header/HeaderDetail.vue";
import ContentHeader from "@/components/admin/home/ContentHeader.vue";

export default {
  components: {
    HeaderDetail,
    ContentHeader,
    draggable
  },
  data() {
    return {
      isLoading: false,
      selectedItem: null,
      saveMessage: "",
      saveDisabled: true,
      submitted: false,
      drag: false,
      roles: ["user", "moderator", "admin"]
    };
  },
  async created() {
    this.doFetchAll();
    this.saveDisabled = true;
    this.saveMessage = "";
    this.submitted = false;
  },
  watch: {
    items: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Header/fetchAll");
      this.isLoading = false;
    },
    addItem() {
      this.items.push(new HeaderItem(true, {}, {}, []));
    },
    async save() {
      this.isLoading = true;
      await this.$store.dispatch("Header/update", this.items);
      await this.$store.dispatch("Header/fetchAll");
      // await this.$store.dispatch("Config/updateSetting", this.settings);
      this.$root.$bvToast.toast("Header settings saved.", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    items() {
      return this.$store.state.Header.items;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}

.custom-control-input {
  color: $color-secondary;
}
</style>
<style lang="scss">
.language-tab {
  .nav-link {
    padding: 2px 2px !important;
  }
}

.no-padding {
  a {
    padding: 0px !important;
  }
}

.content-form {
  padding-top: 15px;
}

.content-switch {
  margin-bottom: 15px;
}

.component-switch {
  margin-left: 15px;
}

</style>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.list-group-item i {
  cursor: pointer;
}

.button {
  margin-top: 35px;
}
</style>